import React, { useState } from "react";

import styled from "styled-components";
import Layout from "../../components/layout";
import Image from "../../components/image";
import SEO from "../../components/seo";
import { motion } from "framer-motion";

import ogImg from "../../images/ogimage.png";
import YoutubeEmbed from "../../components/YoutubeEmbed";

import BetaLogoImg from "../../images/LogoBeta.svg";
import MacImg from "../../images/beta-screen.png";
import PlayBtnImg from "../../images/PlayBtn.svg";
import HowImg1 from "../../images/how-1.png";
import HowImg2 from "../../images/how-2.png";

import FadeInWhenVisible from "../../components/FadeInWhenVisible";
const sm = "640px";
const md = "768px";
const lg = "1024px";

const Blue = "#1F44E8";
const BlueDark = "#1632AC";
const Yellow = "#F6BF41";

const BetaLogo = styled.img`
  width: 132px;
  height: auto;
  @media (min-width: ${lg}) {
    width: 176px;
  }
`;

const Mac = styled.img`
  width: 100%;
  max-width: 1280px;
  height: auto;
  opacity: 0.7;
`;

const Header = styled.header`
  width: 100vw;
  display: flex;
  padding: 2rem;
  justify-content: space-between;
`;

const SmallBtn = styled.a`
  background: ${Blue};
  color: #fff;
  border-radius: 9px;
  padding: 0.5rem 1rem;
  font-size: 0.825rem;
  line-height: 2;
  font-weight: bold;
  display: inline-flex;
  cursor: pointer;
  &:hover {
    background: ${BlueDark};
    color: #fff;
  }
`;

const LgBtn = styled.a`
  background: ${Blue};
  color: #fff;
  border-radius: 9px;
  padding: 1rem 1.5rem;
  font-size: 1rem;
  line-height: 2;
  font-weight: bold;
  display: inline-flex;
  cursor: pointer;
  &:hover {
    background: ${BlueDark};
    color: #fff;
  }
`;

const Hero = styled.section`
  text-align: center;
  position: relative;
  padding: 2rem;
  @media (min-width: ${md}) {
    padding: 4rem;
  }
  h1 {
    line-height: 1em;
    letter-spacing: -0.02em;
    font-size: 3rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    @media (min-width: ${md}) {
      font-size: 4rem;
      margin-top: 2rem;
      margin-bottom: 2rem;
    }
    @media (min-width: ${lg}) {
      font-size: 6rem;
      margin-top: 4rem;
      margin-bottom: 4rem;
    }
  }
`;

const PlayBtn = styled.img`
  position: absolute;
  width: 80px;
  height: 80px;
  left: 50%;
  top: 57%;
  transform: translateX(-50%) scale(1);
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  transform-origin: center center;
  &:hover {
    transform: translateX(-50%) scale(1.1);
  }
  @media (min-width: ${md}) {
    width: 124px;
    height: 124px;
    top: 50%;
  }
`;

const Section = styled.section`
  text-align: center;
  section: max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  padding: 2rem;
  @media (min-width: ${md}) {
    padding: 4rem;
  }
  h2 {
    font-size: 2rem;
    line-height: 1.25em;
    @media (min-width: ${md}) {
      font-size: 3rem;  
    }
  }
  h3 {
    font-size: 1.75rem;
    line-height: 1.25em;
    span {
      color: #a1a1a1;
    }
    @media (min-width: ${md}) {
      font-size: 2rem;
    }
`;

const Features = styled.div`
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    li {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-weight: bold;
      padding: 1rem;
      flex-basis: 50%;
      @media (min-width: ${md}) {
        flex-basis: 14.285714285714286%;
      }
    }
  }
  svg {
    width: 40px;
    height: 40px;
    margin: 1rem auto;
    color: ${Yellow};
  }
`;

const Para = styled.p`
  color: #fff;
  line-height: 1.5em;
  font-size: 1rem;
  max-width: 680px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 4rem;
`;

const ColSection = styled.section`
  @media (min-width: ${md}) {
    display: flex;
  }
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
  div {
    flex-basis: 50%;
    padding: 2rem;
    @media (min-width: ${md}) {
      padding: 4rem;
    }
  }
  h2 {
    font-size: 3rem;
    line-height: 1.25em;
  }
  h3 {
    font-size: 2rem;
    line-height: 1.25em;
    margin-top: 0;
    span {
      color: #a1a1a1;
    }
  }
`;

const BetaList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  border-top: 1px solid #808080;
  li {
    color: ${Yellow};
    padding: 1rem;
    border-bottom: 1px solid #808080;
    font-size: 1.25rem;
    font-weight: bold;
  }
`;

const HowImg = styled.img`
  width: 100%;
  height: auto;
  position: relative;
  z-index: 10;
  margin-top: -10%;
`;

const HowImgBottom = styled.img`
  width: 80%;
  height: auto;
  position: relative;
  z-index: 9;
  margin-top: -40%;
  margin-left: 10%;
  opacity: 0.5;
`;

const Vid = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #202020;
  box-sizig: border-box;
`;

const VidClose = styled.div`
  position: absolute;
  z-index: 10;
  top: 1rem;
  right: 1rem;
  color: #fff;
  cursor: pointer;
  svg {
    width: 32px;
    height: 32px;
    color: #fff;
  }
`;
const BetaPage = () => {
  const [showVid, setShowVid] = useState(false);
  return (
    <Layout>
      <SEO
        title="Portal Beta"
        description="Operate at Lightspeed"
        image={"http://theportalx.com" + ogImg}
      />

      <Header>
        <BetaLogo src={BetaLogoImg} />
        <SmallBtn href="https://form.typeform.com/to/tgmQeHiz" target="_blank">
          Join Beta
        </SmallBtn>
      </Header>

      <FadeInWhenVisible>
        <Hero>
          <h1>Operate at Lightspeed</h1>
          <Mac src={MacImg} alt="Portal on a Mac" />
          <PlayBtn
            src={PlayBtnImg}
            alt="Play Button"
            onClick={() => setShowVid(true)}
          />
        </Hero>
      </FadeInWhenVisible>

      <Section>
        <FadeInWhenVisible>
          <h2>Welcome to the future of data collaboration</h2>
          <Para>
            Portal is a next-generation data platform that enables everyone in
            your organization to find, access, and use all of your
            organization’s data immediately. Break open data silos and unify
            data from any system and any jurisdiction. Create sample stores and
            central data repositories to help everyone find and access the data
            they need. Spin up virtual workspaces and collaborate effortlessly
            with anyone inside and outside of your organization. Use all of your
            organization's data without having to worry about data quality, data
            compliance, or data governance ever again.
          </Para>
        </FadeInWhenVisible>
        <FadeInWhenVisible>
          <h3>
            Get your data ready and available to your entire organization.
            <br />
            <span>Privacy, governance and compliance built in.</span>
          </h3>
        </FadeInWhenVisible>
        <FadeInWhenVisible>
          <Features>
            <ul>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 16l2.879-2.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242zM21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>For data discovery</span>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                  />
                </svg>
                <span>For collaboration across teams</span>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 11H5m14 0a2 2 0 012 2v6a2 2 0 01-2 2H5a2 2 0 01-2-2v-6a2 2 0 012-2m14 0V9a2 2 0 00-2-2M5 11V9a2 2 0 012-2m0 0V5a2 2 0 012-2h6a2 2 0 012 2v2M7 7h10"
                  />
                </svg>
                <span>For data scientists to build models</span>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z"
                  />
                </svg>
                <span>For analysts to find useful info</span>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
                <span>For engineers to test platforms</span>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8 13v-1m4 1v-3m4 3V8M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
                  />
                </svg>
                <span>For ops and business functions</span>
              </li>
              <li>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                  />
                </svg>
                <span>For Sharing internally and externally</span>
              </li>
            </ul>
          </Features>
        </FadeInWhenVisible>
      </Section>

      <FadeInWhenVisible>
        <ColSection>
          <div>
            <h3>How it works</h3>
            <p>
              Getting started on Portal is simple. It all starts with adding
              data into Portal. With our suite of 150 pre-built connectors, data
              owners can add data with a single sign on. The data then goes
              through patent-pending data engines which add metadata about data
              privacy and data quality to augment the original data. The data
              then goes into the Data Catalogue, where it is easily searchable
              by everyone in the organization. And to ensure that it’s not just
              easy to find but also immediately accessible, Portal comes
              equipped with Data Apps that turn raw data into Transformed Data,
              which allows everyone in the organization to access data views
              without having to worry about data privacy implications. From
              there, data can be moved into virtual Workspaces, where teams can
              work on their data projects in one central place. They can pull in
              the data they need, share queries and knowledge through our
              built-in Workspace chat, create synthetic data assets, and much
              more. Data collaboration has never been easier.
            </p>
            <SmallBtn
              href="https://app.pitch.com/app/presentation/004b5a71-99e5-4328-b81d-896265ebea01/264425f6-6e89-4631-b79d-24be6370ef5b"
              target="_blank"
            >
              View the Deck
            </SmallBtn>
          </div>
          <div>
            <HowImg src={HowImg1} />
            <HowImgBottom src={HowImg2} />
          </div>
        </ColSection>
      </FadeInWhenVisible>
      <FadeInWhenVisible>
        <ColSection>
          <div>
            <h3>The Portal Beta Program</h3>
            <p>
              Portal is launching an exclusive Beta Program to give select
              organizations a first glance at the future of data collaboration.
              Beta Program participants will receive $5,000 in usage credits as
              well as three 60 minute sessions of dedicated onboarding support,
              covering anything from data engineering, to compliance, general
              usage, and beyond. And of course you’ll always have our ear for
              feature requests, support, or general product feedback!
            </p>
            <LgBtn href="https://form.typeform.com/to/tgmQeHiz" target="_blank">
              Join the Beta Program
            </LgBtn>
          </div>
          <div>
            <BetaList>
              <li>$5K in credits</li>
              <li>3 x 60 minute dedicated onboarding support</li>
              <li>No setup cost</li>
              <li>No minimum usage cost</li>
              <li>Cancel anytime </li>
            </BetaList>
          </div>
        </ColSection>
      </FadeInWhenVisible>
      {showVid && (
        <Vid>
          <VidClose onClick={() => setShowVid(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#ffffff"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </VidClose>
          <YoutubeEmbed embedId={"vadXA1zEiOw"} />
        </Vid>
      )}
    </Layout>
  );
};

export default BetaPage;
